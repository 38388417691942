import React from 'react'
import Layout from '../components/Layout'
import styles from './frequentQuestions.module.scss'

const Fqr = () => {
  return (
    <Layout>
      <section className={styles.sectionOne}>
        <div className={styles.textContainer}>
          <h2>Preguntas frecuentes </h2>
          <h5>Por qué elegir FluentWorlds®?</h5>
          <p>
            Hay muchas aplicaciones de aprendizaje de idiomas en el mercado,
            pero FluentWorlds® es la única en el mundo que funciona como un
            juego 3D inmersivo. A partir de la primera lección, la aplicación
            FluentWorlds® se centra en "LA CONVERSACIONES EN INGLÉS". Esto
            significa que está diseñada para aprender a HABLAR EN INGLÉS.
          </p>
          <h5>Cuál es la forma más efectiva de aprender Inglés?</h5>
          <p>
            La respuesta es inmersión. Otras aplicaciones de inglés y sistemas
            de aprendizaje de idiomas centran su atención en la lectura y la
            escritura. FluentWorlds® utiliza una forma natural para aprender a
            hablar, permitiéndole una inmersión en mundos tridimensionales en
            los que usted puede experimentar situaciones de la vida real en los
            Estados Unidos interactuando con avatares en lugares como es
            supermercado, la estación de gasolina, el aeropuerto, el hotel, el
            cine y muchos más para así aprender en contexto.
          </p>
          <h5>
            Como puedo aprender a hablar en Inglés con el acento correcto?
          </h5>
          <p>
            Sabemos que un niño primero aprende a HABLAR, luego a escribir y a
            leer. El currículo de aprendizaje de FluentWorlds® está diseñado
            para seguir el mismo proceso.
          </p>
          <br />
          <p>
            FluentWorlds® es una empresa de los Estados Unidos que ha creado un
            juego de aprendizaje virtual único en el mundo el cual combina
            hermosos y atractivos mundos 3D con una tecnología de inteligencia
            artificial y reconocimiento de voz para que su experiencia de
            aprendizaje sea inmersiva y divertida. Los avatares del juego hablan
            con la pronunciación correcta y el sistema evalúa su voz para
            asegurarle un progreso que le permita aprender a hablar con el
            acento correcto.
          </p>
          <h5>Que hace a FluentWorlds® diferente? </h5>
          <p>
            Nuestro sistema de reconocimiento de voz fue creado por Jeff Adams,
            el mismo inventor de Siri y Amazon Alexa, no hay actualmente otro
            sistema para aprender Inglés que tenga esa tecnología.
          </p>
          <h5>
            Como sé que FluentWorlds® es una tecnología efectiva para aprender
            Inglés?
          </h5>
          <p>
            Actualmente, FluentWorlds® está calificado por Apple con 4.9 en la
            tienda app store y con 4.7 en el Google Play Store, miles de
            usuarios en todo el mundo están aprendiendo inglés con
            FluentWorlds®.
          </p>
          <h5>Es FluentWorlds® para todas las edades?</h5>
          <p>
            Nuestra tecnología de aprendizaje se recomienda para participantes
            de 12 años o más. Debido a que FluentWorlds® es un juego en 3D con
            mundos realistas que representan la vida diaria de los
            Norteamericanos, es una herramienta muy efectiva para aprender a
            HABLAR en inglés más rápido y con acento estadounidense. Es una
            forma acelerada para que jóvenes y adultos, hombres y mujeres puedan
            aprender a HABLAR en inglés en contexto.{' '}
          </p>
          <h5>
            Se puede recomendar FluentWorlds® para principiantes que no tienen
            ningún conocimiento de inglés?
          </h5>
          <p>
            Si, ciertamente. Usted puede suscribirse a FluentWorlds® como un
            principiante y después de unas semanas jugando en mundos diferentes,
            alcanzara un nivel de conversación con el que podrá hacerse entender
            en Ingles.
          </p>
          <h5>
            Se recomienda FluentWorlds® para una persona con un nivel de inglés
            avanzado?
          </h5>
          <p>
            Si, desde luego. Para personas con un nivel de Inglés avanzado el
            enfoque se centra en el perfeccionamiento de la pronunciación. Si se
            trata de una persona que ha estudiado Ingles por muchos años pero no
            se siente capaz de hablar en Inglés o lo hace con mesura o
            dificultad, FluentWorlds® y Perfect Accent son herramientas muy
            útiles para superar esos obstáculos y mejorar el acento.{' '}
          </p>
          <h5>
            Cuánto tiempo toma aprender a hablar en inglés con FluentWorlds®?
          </h5>
          <p>
            El enfoque de FluentWorlds® es aprender a HABLAR en contexto
            mediante un juego 3D interactivo, por lo tanto, su progreso de
            aprendizaje será más rápido que con cualquier otro sistema
            educativo.
          </p>
          <br />
          <p>
            Cada estudiante es diferente y los resultados del aprendizaje
            dependen de la disciplina de cada suscriptor y del tiempo que dedica
            a practicar. En promedio estudiantes que inician con un nivel básico
            necesitan alrededor de 52 semanas de práctica diaria con la
            aplicación FluentWorlds® y lecciones en vivo de una hora por semana,
            para tener un dominio eficaz del idioma inglés y poder mantener una
            conversación fluida en inglés. Sin embargo hay algunos estudiantes
            que logran excelentes resultados en un menor tiempo.
          </p>
          <h5>Tiene FluentWorlds® algún tipo de evaluación de mi progreso?</h5>
          <p>
            Si, FluentWorlds® tiene 6 diferentes evaluaciones en el app las
            cuales ocurren en entornos virtuales y generan de forma automática
            un puntaje al finalizar. Al terminar cada mundo virtual hay una
            calificación del progreso obtenido en cada nivel la cual usted puede
            ver y queda grabada en nuestro sistema central.
          </p>
          <h5>Tiene Perfect Accent algún tipo de evaluación de mi progreso?</h5>
          <p>
            Si, Acento Perfecto o Perfect Accent crea al comienzo una "impresión
            única de su voz" y hace recomendaciones sobre qué parte de su
            pronunciación usted necesita mejorar. En la medida en que avanza en
            el uso del app, un monitor de evaluación de pronunciación va
            cambiando de color indicando el nivel de progreso en su acento.
            Perfect Accent es un app ideal para cualquier persona que quiera
            aprender a hablar en Inglés con el acento correcto y es una
            herramienta ideal para profesionales que necesitan mejorar su
            vocabulario y pronunciación en áreas específicas relacionadas con su
            profesión.
          </p>
          <h5>Qué se incluye en una suscripción de FluentWorlds®?</h5>
          <p>
            Esto depende del paquete que usted compro. El Paquete PRO es el que
            la mayoría de nuestros suscriptores prefiere por lo que incluye
            FluentWorlds®, Perfect Accent y las clases en vivo de la Academia
            Virtual de FluentWorlds® o FluentWorlds® Academy.
          </p>
          <h5>
            Con que frecuencia puedo tener acceso la aplicación después de pagar
            por la suscripción?
          </h5>
          <p>
            Tener la aplicación FluentWorlds® en su dispositivo móvil es como
            tener un profesor de Inglés disponible en el momento que desee.
            Puede acceder a su aplicación FluentWorlds® 24/7 a través de sus
            dispositivos ios o androide, incluido su teléfono, tableta así como
            también su computadora de mesa o laptop portátil.{' '}
          </p>
          <br />
          <p>
            La aplicación funciona con internet o sin conexión a internet. Para
            la parte de reconocimiento de voz, deberá estar conectado al
            internet mediante una red de datos o wifi.{' '}
          </p>
          <br />
          <p>
            La aplicación Perfect Accent solo está diseñada para teléfonos
            inteligentes y tabletas con sistema operativo ios o android.
          </p>
          <h5>Recibiré un certificado después de completar el curso?</h5>
          <p>
            Sí, FluentWorlds® emitirá un certificado oficial para cada
            estudiante que complete exitosamente las evaluaciones y apruebe la
            entrevista final que está enfocada en la conversación en Ingles.
          </p>
          <h5>Como puedo pagar por la suscripción a FluentWorlds®?</h5>
          <p>
            Nuestra página tiene varias alternativas de pago, usted puede
            suscribirse individualmente de forma anual o mensual a FluentWorlds®
            , a Perfect Accent o tomar ventaja del precio especial del Paquete
            Pro que incluye FluentWorlds® , a Perfect Accent y FluentWorlds®
            Academy (Clases virtuales en vivo con profesores de los Estados
            Unidos). También puede suscribirse a un paquete familiar que lo
            incluye a usted y a 5 miembros de su familia por un precio especial.
            Los pagos se pueden hacer de forma segura por PayU, PayPal, Visa o
            Master Card.
          </p>
          <h5>
            Puedo aprender solo con el app o necesito adicionar las clases en
            mundos virtuales con profesores de los Estados Unidos?
          </h5>
          <p>
            El app de FluentWorlds® está diseñado para que usted interactúe con
            nuestra tecnología de reconocimiento de voz y empiece a decir
            expresiones en Ingles desde la primera lección. En la medida en que
            avanza en los niveles, usted empieza a pronunciar en Ingles y a
            mejorar paso a paso su acento al interactuar con los personajes
            digitales del app. Solo con el app usted puede aprender a hablar en
            Inglés. Las clases en vivo en mundos virtuales con profesores de los
            Estados Unidos son una herramienta adicional que ayuda a reforzar el
            aprendizaje que usted realiza con el app. Los estudiantes que
            adquieren el Paquete Pro tienen un nivel de aprendizaje más rápido.{' '}
          </p>
          <h5>
            Necesito suscribirme a los dos apps de FluentWorlds® y Perfect
            Accent o puedo suscribirme a uno solo?
          </h5>
          <p>
            El app de FluentWorlds® es esencial para su aprendizaje del Inglés.
            El app de Perfect Accent es un complemento para aquellas personas
            que desean acelerar su aprendizaje en cuanto a la pronunciación
            relacionada con temas y vocabulario de áreas específicas de trabajo,
            por ejemplo Ingles de Negocios, Ingles de Finanzas, preparación para
            el examen TOEFL, etc. Se recomienda suscribirse a los dos apps
            tomando ventaja del precio reducido del PAQUETE PRO.
          </p>
          <h5>Debo suscribirme de forma mensual o anual?</h5>
          <p>
            Usted puede elegir entre las dos opciones. La suscripción anual es
            más conveniente porque le permite reducir el costo y le facilita
            ponerse la meta personal de lograr hablar en Ingles bien en 12
            meses. La suscripción mensual le permite pagar cada mes hasta cuando
            sienta que su nivel de conversación es satisfactorio. Nuestro
            sistema de está diseñado para aprender a hablar en Ingles de manera
            fluida en 52 semanas. Son en total 49 niveles y 6 evaluaciones razón
            por la cual recomendamos la suscripción anual.
          </p>
          <h5>
            Qué tipo de conexión a internet necesito para utilizar el app de
            FluentWorlds® o el app de Perfect Accent?
          </h5>
          <p>
            Una vez usted descarga el app por medio de su plan de datos o a
            través de una conexión wifi, podrá usarlo en su teléfono, tableta,
            desktop o laptop. El app puede funcionar perfectamente con internet
            o sin conexión a internet. La conexión a internet por wifi o
            mediante su plan de datos es necesaria solo para la función de
            reconocimiento de voz del app. El consumo de datos del app una vez
            instalado es mínimo.
          </p>
          <h5>
            Puedo usar mi suscripción de FluentWorlds® en múltiples
            dispositivos?
          </h5>
          <p>
            Sí, puede acceder a su aplicación FluentWorlds® en su teléfono,
            tableta y computadora de escritorio o computadora portátil (laptop).
            Una suscripción solo se puede usar simultáneamente en 2
            dispositivos.{' '}
          </p>
          <h5>Es la aplicación de FluentWorlds® gratuita?</h5>
          <p>
            El registro y la descarga de la aplicación FluentWorlds® son
            completamente gratuitos durante las primeras tres lecciones. Para
            desbloquear las otras 52 lecciones o mundos, usted debe tener una
            suscripción pagada.
          </p>
          <h5>Es la aplicación Perfect Accent gratuita?</h5>
          <p>
            El registro y la descarga de la aplicación Perfect Accent son
            completamente gratuitos para unas pocas categorías. Para desbloquear
            todas las categorías, usted debe tener una suscripción pagada.
          </p>
          <h5>En que se basa el currículo de FluentWorlds®?</h5>
          <p>
            Nuestro plan de estudios de mundos 3D está creado por expertos en
            educación de idiomas, incluida la Ph.D. Dra. Linda Bradford. El
            currículo del programa se enfoca en la CONVERSACION y está basado en
            la escala de MCER o CEFR, Marco Común Europeo de Referencia para las
            Lenguas.
          </p>
          <h5>Qué tipo de tecnología utiliza FluentWorlds®?</h5>
          <p>
            Cuando usted paga una suscripción a FluentWorlds® obtiene el sistema
            tecnológicamente más avanzado del mundo basado en inteligencia
            artificial para aprender a HABLAR en inglés en contexto y con el
            acento correcto. El concepto de juego en FluentWorlds® fue diseñado
            por el fundador de la compañía Atari. El sistema de reconocimiento y
            análisis de su voz fue diseñado por Jeff Adams el inventor de Siri y
            Amazon Alexa.{' '}
          </p>
          <h5>
            Tiene FluentWorlds® algún tipo de publicidad que uno debe ver para
            poder avanzar?
          </h5>
          <p>
            No. Cuando aprendes a hablar en inglés con FluentWorlds® te enfocas
            en divertirte aprendiendo, no en mirar anuncios.
          </p>
          <h5>
            Hay algún tipo de motivación en el juego de aprendizaje
            FluentWorlds® cuando uno está progresando?
          </h5>
          <p>
            Cada logro en su avance tiene unas estrellas de recompensa al final
            de cada mundo o nivel.
          </p>
          <h5>Cómo accedo a FluentWorlds® en una computadora?</h5>
          <p>
            Puede usar el mismo nombre de usuario y contraseña para su
            computadora, su computadora portátil, su teléfono inteligente o su
            tableta.
          </p>
          <h5>
            Qué sucede después de que pago uno de los paquetes de suscripción de
            FluentWorlds®?
          </h5>
          <p>
            Recibirá un mensaje por correo electrónico confirmando su
            suscripción y varios enlaces para descargar la aplicación y crear un
            nombre de usuario y una contraseña para desbloquear todos los
            niveles de las aplicaciones FluentWorlds® o Perfect Accent.
          </p>
          <h5>Donde está ubicado FluentWorlds®?</h5>
          <p>
            La sede principal de FluentWorlds® está en Provo Utah, Estados
            Unidos. En Colombia tenemos una alianza con la compañía Edival de
            Cali quienes se encargan de la parte administrativa de nuestra
            operación en Colombia. Edival forma parte del Grupo Carval, una
            prestigioso consorcio empresarial de Colombia.
          </p>
          <h5>En que se enfocan las lecciones de FluentWorlds® Academy?</h5>
          <p>
            Cada lección en vivo se centra en los mismos mundos que puedes ver
            en la aplicación FluentWorlds®. Una vez que usted pague por su
            suscripción, recibirá un mensaje por correo electrónico del maestro
            asignado que le dará instrucciones sobre cómo se efectuara su
            evaluación de forma digital. De acuerdo a los resultados de la
            evaluación el maestro establecerá su nivel y le asignará a un grupo
            de su mismo nivel con un profesor de Inglés nacido en los Estados
            Unidos que también habla en Español.
          </p>
          <h5>Cuál es la metodología de FluentWorlds® Academy?</h5>
          <p>
            Usted se convierte en un avatar en estas clases, antes de iniciar la
            clase puede elegir el tipo de ropa que quiere usar, cambiar el color
            de su piel o de su cabello. Cada lección se inicia con una breve
            charla del maestro sobre el tema que se revisará durante la lección
            el cual está relacionado con el contenido del FluentWorlds® app. El
            enfoque de cada lección es inglés conversacional, el maestro tendrá
            con usted y otros estudiantes interacciones en increíbles mundos 3D
            para en los que usted puede caminar, sentarse, hablar, levantar la
            mano, correr, volar, etc. para así aprender en contexto.
          </p>
          <h5>Cuánto dura cada lección de FluentWorlds®Academy?</h5>
          <p>Cada lección es de una hora.</p>
          <h5>Cómo programo las lecciones en vivo de FluentWorlds®Academy?</h5>
          <p>
            Después de que pague su suscripción Paquete Pro, un maestro se
            comunicará con usted vía email para coordinar el horario más
            apropiado en función de la disponibilidad de maestros. Una vez que
            usted elija las horas, el maestro asignado le enviará una
            confirmación y un enlace para conectarse a la hora acordada mediante
            nuestro sistema de conferencia virtual 3DMeet.{' '}
          </p>
          <h5>
            Cuantas lecciones recibo por mes de FluentWorlds®Academy con la
            suscripción Paquete Pro?
          </h5>
          <p>Son 4 lecciones grupales por mes de una hora cada lección.</p>
          <h5>
            Que requisitos de equipo se requieren para conectarme a las clases
            en vivo de FluentWorlds®Academy?
          </h5>
          <p>
            Para participar en la lección, necesitará una computadora de
            escritorio, o una computadora portátil, o una computadora ibook o
            Imac. Necesitará que su equipo tenga el navegador Google Chrome,
            altavoces, micrófono y cámara web.
          </p>
          <br />
          <p>
            Su equipo necesitara una conexión a Internet de aproximadamente 30
            Mbps o superior (Mbps =Megabytes por segundo).
          </p>
          <h5>Como puedo saber la velocidad de mi conexión a Internet?</h5>
          <p>
            Se recomienda ir a la página de{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.speedtest.net/"
            >
              Speedtest
            </a>{' '}
            y darle clic al botón GO para que usted verifique la velocidad de su
            conexión a Internet.
          </p>
          <h5>
            Cómo sé si las lecciones en vivo de FluentWorlds® Academy son
            adecuadas para mí?
          </h5>
          <p>
            Las lecciones de la Academia FluentWorlds® son adecuadas para todos
            los estudiantes que aprenden con la aplicación, las lecciones en
            vivo están diseñadas de acuerdo con el mismo plan de estudios
            utilizado en la aplicación FluentWorlds®.
          </p>
          <h5>
            Si no puedo obtener la respuesta a mi pregunta en esta lista, cómo
            puedo contactar a FluentWorlds®?
          </h5>
          <p>
            Hay varias maneras diferentes de ponerse en contacto con el equipo
            de Atención al Cliente de FluentWorlds®. Primero contáctenos al
            siguiente correo electrónico:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contacto@FluentWorlds.co"
            >
              contacto@FluentWorlds.co
            </a>{' '}
            y asegúrese de enviar su nombre completo, ciudad, email y número de
            teléfono. Si ya tiene una cuenta de FluentWorlds®, envíenos también
            la dirección de correo electrónico que utilizó para registrarse en
            FluentWorlds®. Esto hace que sea más rápido para nosotros encontrar
            su cuenta y responder su pregunta.
          </p>
          <br />
          <p>
            Asimismo puede enviar un correo electrónico a{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:happytohelp@FluentWorlds.co"
            >
              happytohelp@FluentWorlds.co
            </a>{' '}
            con su pregunta.
          </p>
          <br />
          <p>
            Uno de nuestros representantes de servicio al cliente en Colombia se
            comunicará con usted por correo electrónico para responder sus
            preguntas o lo llamará para discutir cualquier inquietud que pueda
            tener.
          </p>
          <h5>
            Cuál es su política para las lecciones en vivo perdidas o para
            cancelar una lección?
          </h5>
          <p>
            En caso de que no pueda participar en una lección en vivo a la hora
            programada con el maestro, usted puede enviar un correo electrónico
            al maestro al menos 24 horas antes del día y la hora de la lección
            programada.
          </p>
          <br />
          <p>
            Recibirá una confirmación por correo electrónico para la cancelación
            de esa clase y su reprogramación.
          </p>
          <br />
          <p>
            Si no se comunica con el maestro con 24 horas de anticipación,
            perderá esa lección que pago, independientemente del motivo.
          </p>
          <h5>
            Qué sucede cuando hay dificultades técnicas para las lecciones en
            vivo de FluentWorlds® Academy?
          </h5>
          <p>
            FluentWorlds no se hace responsable de los problemas de conexión a
            Internet o dificultades técnicas con su equipo informático.
          </p>
          <br />
          <p>
            Nuestro sistema de conferencia 3DMeet es muy eficiente y rara vez
            tiene un problema técnico, sin embargo, en el caso de una eventual
            dificultad técnica de nuestro lado, recibirá la lección en un
            momento diferente una vez que se resuelva el problema técnico.
          </p>
          <br />
          <p>
            Es conveniente que se conecte a la lección por lo menos 10 minutos
            antes de que la clase comience para entrar su nombre y caracterizar
            a su avatar.
          </p>
          <h5>
            Puedo participar en las clases en vivo desde mi teléfono o tableta?
          </h5>
          <p>
            No, las lecciones en vivo de FluentWorlds® Academy solo se pueden
            accesar desde una computadora de escritorio, o una computadora
            portátil, o una computadora ibook o Imac con el navegador Google
            Chrome. Su equipo necesita una conexión a Internet, altavoces,
            micrófono y cámara web. (Asegúrese de usar solo Google Chrome para
            conectarse a nuestro sistema 3DMeet Live Academy). Si no usa Google
            Chrome, no podrá conectarse a nuestro sistema 3DMeet.
          </p>
          <h5>
            Necesito instalar algún software para poder tener acceso a las
            lecciones en vivo de FluentWorlds® Academy?
          </h5>
          <p>
            El acceso al sistema de conferencia virtual 3D Meet requiere
            exclusivamente el uso del navegador Google Chrome, no se puede usar
            con Safari, Internet Explorer, Edge, Opera o Firefox. Al conectarse
            es posible que al usar la función de compartir pantalla Google
            Chrome le pida de forma automática instalar un plugin o software
            para habilitar esa función lo cual solo toma unos 10 segundos.{' '}
          </p>
          <h5>Cómo puedo hacer una sugerencia?</h5>
          <p>
            Si tiene una idea o sugerencia, le recomendamos que envíe sus ideas
            sobre recomendaciones a FluentWorlds® a nuestro correo electrónico:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contacto@FluentWorlds.co"
            >
              contacto@FluentWorlds.co
            </a>{' '}
            Siempre incluya su nombre completo, ciudad, correo electrónico que
            utilizó para su registro y su número de teléfono.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Fqr
